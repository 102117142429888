@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);

// Define defaults for each variable.

$base-font-family: Georgia, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;
$title-font-family: "Roboto", sans-serif;
$title-font-weight: 700 !default;

$spacing-unit:     30px !default;

$text-color:            hsla(0,0%,100%,.75) !default;
$title-text-color:      #E8E9E9 !default;
$background-color:      #191b1f !default;
// $background-color-dark: darken($background-color, 50%) !default;
$background-color-dark: #090A0B !default;
$brand-color:           #099 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
